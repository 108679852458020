import React, { FC } from 'react';
import { Icon } from '@wmgtech/legato';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { EIconTypes } from './ToastService';

type TToastIcon = {
  iconName: IconName
}

const ToastIcon: FC<TToastIcon> = ({ iconName  = EIconTypes.coffee }) => (<Icon name={iconName} className="fa-lg"/>);

export default ToastIcon;