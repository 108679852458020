import React, { FC } from 'react';
import { cn } from 'utils';
import { FieldRenderProps } from 'react-final-form';
import { Textarea as LegatoTextarea } from '@wmgtech/legato';
type TTextareaProps = JSX.IntrinsicElements['textarea'] & {
  label?: string
  className?: string
}

export const Textarea: FC<TTextareaProps> = (props) => {
  const { label, required, disabled, placeholder, className, ...intrinsicProps } = props;
  return (
    <div className={cn('form-group', {
      'required': required
    }, props.className)}>
      {label && <label className="control-label">{label}</label>}
      {required && <span className="fa" />}
      <textarea
        {...intrinsicProps}
        disabled={disabled}
        className={cn('form-control', className)} placeholder={placeholder}
      />
    </div>
  );
};

export const FFTextarea: FC<FieldRenderProps<string> & TTextareaProps> = (props) => {
  // meta should be extracted explicitly to not render meta="[object Object]" in the browser,
  // will be handled when add a validations and meta will be used for that
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { input, label, required, disabled, placeholder, className, name } = props;
  return (
    <LegatoTextarea
      value={input.value}
      name={name}
      placeholder={placeholder}
      className={className}
      onChange={input.onChange}
    />
  );
};
