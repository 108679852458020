import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TPartyEntity, TPartyName } from 'domains/party/types';
import { EPartyType } from 'modules/common/types/partyTypes';
import { convertDateAllNumbers } from 'modules/common/helpers/dateHelpers';
import { EPartyHistoryEntity, PartyHistoryItem } from 'domains/history';
import { tMessage } from 'modules/common/helpers/translation';
import { ArtistImage } from 'modules/common/components/ArtistImage';
import { ViewField } from '@grow-components/ViewField/ViewField';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { EField, useHistoryElement } from './HistoryTooltip';
import { NameItem } from './NameItem';
import { useSettings } from 'domains/env';
import { Tooltip } from '@wmgtech/legato';
import { Icon } from '@grow-components/Icon/Icon';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';

interface Props {
  historyGroupByEntity?: Record<EPartyHistoryEntity, ReadonlyArray<PartyHistoryItem>>;
  party: {
    party: TPartyEntity;
    names: TPartyName[];
  };
  type?: 'view' | 'history';
}

const PartyInfo: React.FC<Props> = ({ historyGroupByEntity, party }) => {
  const { t } = useTranslation('party_labels');
  const { dictionaries, getCountry } = useDictionaries();
  const { settings } = useSettings();
  const { dataImages, loadingImages } = usePartyImage({ partyId: party.party.partyId });
  const partyType = useMemo(() => party.party.partyType, [party]);
  const pkaNameValue = party.names[0].nameValue;

  const countryOfOriginName = getCountry(party.party.countryOfOriginId)?.name;
  const languageOfOriginName = dictionaries?.languages.find((l) => l.languageVariantId === party.party.languageOfOriginId)?.name;
  const beginDate = convertDateAllNumbers(
    settings?.dateFormat,
    party?.party?.dateOfBeginningYear,
    party?.party?.dateOfBeginningMonth,
    party?.party?.dateOfBeginningDay,
  );
  const endDate = convertDateAllNumbers(
    settings?.dateFormat,
    party?.party?.dateOfEndYear,
    party?.party?.dateOfEndMonth,
    party?.party?.dateOfEndDay,
  );

  const { label: historyLabel } = useHistoryElement(historyGroupByEntity);

  return (
    <div className="party-page">
      <div className="row flex align-center mt-20 mb-40">
        <div className="col-6 col-md-4 col-lg-3">
          <ArtistImage
            className="artist-image"
            isCentred
            artistName={pkaNameValue}
            images={loadingImages ? [] : dataImages}
            expandable
          />
          {/* GP-4211 hide signed icon until further notice */}
          {typeof party.party.signed === 'number' && <div className='signed-pill'>
            <Tooltip placement='bottom' className='flex align-center' message={t('tooltips:signed_wmg')} colorType='dark'>
              <div className='flex'>
                <Icon icon='wmg_logo' className='signed-icon'/><span className='signed-text'>{t('signed_wmg')}</span>
              </div>
            </Tooltip>
          </div>}
        </div>
        <div className="col-6 col-md-8 col-lg-9">
          <div className="row">
            <div className="col-md-4">
              <ViewField
                label={
                  historyLabel({
                    label: t('party_type'),
                    tooltip: t('tooltips:party_type'),
                    gkey: EPartyHistoryEntity.PARTY,
                    field: EField.partyType,
                  })
                }
                value={tMessage(partyType)}
              />
            </div>
            <div className="col-md-4">
              {party?.party.partyType === EPartyType.PERSON ? (
                <ViewField
                  label={
                    historyLabel({
                      label: t('person_type'),
                      tooltip: t('tooltips:person_type'),
                      gkey: EPartyHistoryEntity.PARTY,
                      field: EField.partySubType,
                    })
                  }
                  value={tMessage(party?.party?.partySubType)}
                />
              ) : (
                <ViewField
                  label={
                    historyLabel({
                      label: t('group_type'),
                      tooltip: t('tooltips:group_type'),
                      gkey: EPartyHistoryEntity.PARTY,
                      field: EField.partySubType,
                    })
                  }
                  value={tMessage(party?.party?.partySubType)}
                />
              )}
            </div>
            <div className="col-md-4">
              <ViewField
                label={
                  historyLabel({
                    label: t('origin_country'),
                    tooltip: t('tooltips:country_of_origin'),
                    gkey: EPartyHistoryEntity.PARTY,
                    field: EField.countryOfOrigin,
                  })
                }
                value={countryOfOriginName}
              />
            </div>
          </div>
          <div className="row field-margin">
            <div className="col-md-4">
              <ViewField
                label={
                  historyLabel({
                    label: t(partyType === EPartyType.PERSON ? 'date_birth' : 'date_formed'),
                    tooltip: t('tooltips:beginning_date'),
                    gkey: EPartyHistoryEntity.PARTY,
                    field: EField.dateOfBeginning,
                  })
                }
                value={beginDate}
              />
            </div>
            <div className="col-md-4">
              <ViewField
                label={
                  historyLabel({
                    label: t(partyType === EPartyType.PERSON ? 'date_death' : 'date_disbanded'),
                    tooltip: t('tooltips:ending_date'),
                    gkey: EPartyHistoryEntity.PARTY,
                    field: EField.dateOfEnd,
                  })
                }
                value={endDate}
              />
            </div>
            <div className="col-md-4">
              <ViewField
                label={
                  historyLabel({
                    label: t('origin_language'),
                    tooltip: t('tooltips:language_of_origin'),
                    gkey: EPartyHistoryEntity.PARTY,
                    field: EField.languageOfOrigin,
                  })
                }
                value={languageOfOriginName}
              />
            </div>
          </div>
        </div>
      </div>

      <>
        {party?.names?.map((name, idx) =>
          <NameItem
            key={`NameItem_${name.nameId}_${idx}`}
            name={name}
            idx={idx}
            historyGroupByEntity={historyGroupByEntity}
            partyType={partyType}
            pkaNameValue={pkaNameValue}
          />)}
      </>
    </div>
  );
};

export default PartyInfo;
