import React, { FC, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { capitalizeFirstLetter } from 'modules/common/helpers/formatArtistData';
import { copyToClipboard } from 'modules/common/helpers/copyToClipboard';
import { ArtistImage } from 'modules/common/components/ArtistImage/ArtistImage';
import { AssignIsniBtn } from 'modules/common/components/isniAssign/AssignIsniBtn';
import { Identifier } from 'modules/common/types/artistTypes';
import { Icon } from '@grow-components/Icon/Icon';
import { Button } from '@wmgtech/legato';
import { age, assignedToDifferentParty, IDS_LABELS } from 'modules/common/helpers/IsniHelpers';
import {
  EIsniSearchParam,
  TIsniSearchData,
  TIsniQueryParams,
  TIsniSearchParams,
  TIsniMoreInfoQueryParams
} from 'modules/common/types/isniTypes';
import { Tooltip } from '@grow-components/Tooltip';
import { useTranslation } from 'react-i18next';
import compact from 'lodash/fp/compact';
import { GroupItem, IGroupItem } from './groupItem';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { createExternalLinks } from 'modules/common/helpers/createExternalLink';
import { isniTypeDependantLabels } from 'domains/party/utils';
import { formatPersonalInfo, keyIdentifiersByType } from 'domains/isni/utils';

import styles from './isni-result-item.module.scss';
import { changeDateDashToSlash } from 'modules/common/components/PartyHistory/helpers';
import { useSettings } from 'domains/env';
import { EButtonColors, EButtonStyles } from 'modules/common/types/legatoTypes';
import { usePartyImage } from 'domains/party/hooks/usePartyImage';

interface IResultItemProps {
  item: any
  isniSearchData?: TIsniSearchData
}

export const IsniResultItem: FC<IResultItemProps> = ({ item, isniSearchData }) => {
  const { t } = useTranslation();
  const { t: tLabels } = useTranslation('party_labels');
  const { nameId } = useParams<TIsniSearchParams>();
  const { params } = useQueryParams<TIsniQueryParams>();
  const { createURL: isniMoreInfoUrl } = useQueryParams<TIsniMoreInfoQueryParams>();
  const cameFrom = params.cameFrom;
  const { settings } = useSettings();
  const itemSpotifyId = item.identifiers.find((id: Identifier) => id.type === EIsniSearchParam.SPOTIFY ); 
  const { dataImages } = usePartyImage({ spotifyId: itemSpotifyId ? itemSpotifyId.value : undefined });
  if (!isniSearchData) return null;

  const isniAssignedToDifferentParty = useMemo(() => assignedToDifferentParty(item, isniSearchData.partyId), [item, isniSearchData]);

  const goToMoreInfo = useCallback(() => {
    const queryParams = {
      cameFrom,
      partyId: isniSearchData.partyId,
      partyName: isniSearchData.name
    };

    window.open(isniMoreInfoUrl(queryParams, `/isni-more-info/${nameId}/${item.isni}`, window.location.hash));
  }, [item, nameId]);

  const nameGroup = useMemo<ReadonlyArray<IGroupItem>>(
    () => [{
      label: capitalizeFirstLetter(item.type),
      value: isniSearchData.searchParam !== EIsniSearchParam.NAME ? item.name : undefined,
    }],
    [item],
  );

  const labels = useMemo(() => isniTypeDependantLabels(item.type), [item]);

  const dateGroup = useMemo<ReadonlyArray<IGroupItem>>(
    () => [
      {
        label: tLabels(labels.dateStart),
        value: changeDateDashToSlash(item.birthDate, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY') && compact([changeDateDashToSlash(item.birthDate, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY'), (!item.deathDate ? age(item) : undefined)]).join(' '),
      },
      { label: tLabels(labels.startPlace), value: item.birthPlace },
      {
        label: tLabels(labels.dateEnd),
        value: changeDateDashToSlash(item.deathDate, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY') && compact([changeDateDashToSlash(item.deathDate, settings?.dateFormat?.toUpperCase() || 'MM/DD/YYYY'), (item.type === 'person' ? age(item) : undefined)]).join(' '),
      },
      { label: tLabels('country'), value: item.countryOfOrigin }
    ],
    [tLabels, item, settings],
  );

  const isniGroup = useMemo<ReadonlyArray<IGroupItem>>(
    () => [{ label: 'ISNI', value: isniSearchData.searchParam === EIsniSearchParam.NAME ? formatISNIStatic(item.isni) : undefined }],
    [isniSearchData, item],
  );

  const comments = useMemo<ReadonlyArray<IGroupItem>>(
    () =>  [{ label: t('comments'), value: item.comment }],
    [item],
  );

  const navigateToParty = useCallback(() =>
    window.open(`/party/${item.assignedNames[0].partyId}`), [item]);

  return <div className='isni-item'>
    <div className='row isni-item__headline'>
      <div className='col col-md-1' />
      <div className='col col-md-1 search-param'>
        {isniSearchData.searchParam === EIsniSearchParam.NAME ? capitalizeFirstLetter(item.type) : 'ISNI'}
      </div>
      <div className='col col-md-10 headline-bold'>
        {isniSearchData.searchParam === EIsniSearchParam.NAME ? item.name : formatISNIStatic(item.isni)}
        {
          isniAssignedToDifferentParty ? (
            <Tooltip text={t(`tooltips:${isniAssignedToDifferentParty ? 'isni_currently_assigned_disabled' : 'isni_currently_assigned_active'}`)}>
              <Button
                className='btn-link isni-currently-assigned'
                icon='angle-right'
                label={t('isni_currently_assigned')}
                onClick={navigateToParty}
                containerStyle={EButtonStyles.link}
              />
            </Tooltip>
          ) : null
        }
      </div>
    </div>
    <div className='row isni-item__info'>
      <div className='col-3 col-sm-2 col-md-2 col-lg-1'>
        <ArtistImage images={dataImages} className={styles.partyImage} artistName={item.name.lastName} expandable />
      </div>
      <div className='col-9 col-sm-10 col-md-10 col-lg-11 isni-item__data'>
        <GroupItem list={nameGroup} />
        <GroupItem list={dateGroup} />
        <GroupItem list={isniGroup} />
        <GroupItem list={comments} />
        <div className='isni-item__group'>
          {item.identifiers.map((identifier: Identifier, index: number) => {
            if(identifier.type === 'MERGED_ISNI') return;
            const link = createExternalLinks(identifier.type, identifier.value);

            return (
              identifier.type !== 'ISNI' && <div key={`isniIdentifier__${index}`} className='isni-item__li'>
                <div className='isni-item__label'>
                  {link.link ?
                    <a href={link.link} className={'isni-item__link'} target='_blank' rel="noopener noreferrer">
                      {IDS_LABELS[identifier.type].label}
                    </a> :
                    IDS_LABELS[identifier.type].label
                  }
                </div>
                <div className='isni-item__value clip'>
                  {identifier.value}&nbsp;
                  <span onClick={() => copyToClipboard(identifier.value)}><Icon icon='copy clip-icon'/></span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    <div className='col-1 col-sm-2 col-md-2 col-lg-1' />
    <div className='col-11 col-sm-10 col-md-10 col-lg-11 isni-item__actions'>
      <Tooltip text={t('tooltips:assign_isni_button')}>
        <AssignIsniBtn
          isni={item.isni}
          ids={keyIdentifiersByType(item.identifiers)}
          isniComment={item.comment}
          partyName={isniSearchData.name}
          personalInfo={formatPersonalInfo(item)}
          partyId={isniSearchData.partyId}
          notAssignable={isniAssignedToDifferentParty}
          buttonColor={EButtonColors.info}
          buttonContainer={EButtonStyles.solid}
        />
      </Tooltip>
      <Tooltip text={t('tooltips:more_info_button')}>
        <Button
          label={t('search:isni_details')}
          colorType={'secondary-black'}
          containerStyle={EButtonStyles.outline}
          icon='arrow-up-right-from-square'
          onClick={goToMoreInfo}
          className={styles.moreInfoBtn}
        />
      </Tooltip>
    </div>
  </div>;
};
