import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon } from '@grow-components/Icon/Icon';
import { DropDown, IRenderArguments, List } from 'modules/common/components/DropDown';

import styles from './create-btn.module.scss';
import { Button, Icon as IconLegato } from '@wmgtech/legato';
import dropdownBtnStyles from 'modules/common/styles/components/dropdown-btn.module.scss';
import { Tooltip } from '@grow-components/Tooltip';

export const CreateBtn: FC = () => {

  const { t } = useTranslation();

  const CREATE_OPTIONS = useMemo(() => [
    {
      value: '/party/person/create',
      label: <div className={styles.linkContent}>
        <Icon icon="user" className={styles.linkIcon} />{t('common:create_person')}
      </div>
    },
    {
      value: '/party/group/create',
      label: <div className={styles.linkContent}>
        <Icon icon="users" className={styles.linkIcon} />{t('common:create_group')}
      </div>
    },
  ], [t]);

  const header = useCallback<(p: IRenderArguments) => JSX.Element>(
    ({ updateStatus }) => (
      <Tooltip text={t('tooltips:create_new_party')} block>
        <Button
          icon={<IconLegato name="chevron-down" />}
          label={t('create')}
          containerStyle='solid'
          colorType='secondary-black'
          className={dropdownBtnStyles.dropdownBtn}
          onClick={() => updateStatus(showContent => !showContent)}
        />
      </Tooltip>
    ), [t],
  );

  const item = useCallback(
    ({ value, label, updateStatus }) => (
      <Link onClick={() => updateStatus(false)} to={value} className={styles.link}>{label}</Link>
    ),
    [],
  );

  const content = useCallback<(props: IRenderArguments) => JSX.Element>(
    ({ updateStatus }) => (
      <List
        options={CREATE_OPTIONS}
        renderItem={(selectOption) => item({ ...selectOption, updateStatus })}
        className={styles.list}
      />
    ), [item],
  );

  return (
    <DropDown
      className={styles.container}
      renderHead={header}
      renderContent={content}
    />
  );
};
