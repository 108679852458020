import React, { FC, useCallback, useMemo } from 'react';
import { Layout } from 'modules/common/components/Layout';
import { cn, createEditPartyPageLink, createPartyHistoryPageLink } from 'utils';
import { Trans, useTranslation } from 'react-i18next';
import PartyInfo from 'modules/pages/PartyPage/components/PartyInfo';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { PartyParams } from 'modules/common/types/routeTypes';
import { useGetParty, usePublishToRAD } from 'domains/party/hooks';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { RecordChangeTimeAndAuthor } from '@grow-components/RecordChangeTimeAndAuthor/RecordChangeTimeAndAuthor';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Banner } from '@grow-components/Banner/Banner';
import { Icon } from '@grow-components/Icon/Icon';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { Button } from '@wmgtech/legato';
import { EButtonSizes, EButtonStyles } from 'modules/common/types/legatoTypes';
import styles from './ViewPartyPage.module.scss';
import { useIsPartyLocked } from 'modules/common/hooks/useIsPartyLocked';
import { BasicSpinner } from '@grow-components/Spinners';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import { EIdentifierType } from 'modules/common/types/partyTypes';

export const ViewPartyPage: FC = () => {
  const { t } = useTranslation();
  const { canEditParty, canViewHistory, canRepublishToRAD } = usePermissions();
  const { loading: loadingDictionaries } = useDictionaries();

  const h = useHistory();
  const { id } = useParams<PartyParams>();
  const { isPartyLoading, partyData } = useGetParty(+id);
  const pkaName = partyData?.names[0];

  const isPartyLocked = useIsPartyLocked(partyData);

  const { publishToRadHandler, publishToRadLoading } = usePublishToRAD(pkaName?.nameValue as string);

  const hasUAID = useMemo(() =>
    partyData?.names?.some(name => name?.identifiers?.some(id => id.identifierType === EIdentifierType.UAID))
  , [partyData]);

  const title = useMemo<JSX.Element>(() => (
    <Tooltip
      text={t('tooltips:header_level_name')}
      place={'bottom'}
    >{pkaName?.nameValue}</Tooltip>
  ), [pkaName]);

  const lockedBannerContent = useMemo(() => partyData ? <div className={styles.bannerContent}>
    <Icon icon='circle-minus' className={styles.bannerIcon} />
    <Trans i18nKey="banners:party_locked" values={{ user: partyData.party.lock?.createdBy }}>
      This Party is currently being edited by <strong>{partyData.party.lock?.createdBy}</strong> and is temporarily locked. Please contact them if you need to make changes, or try again later.
    </Trans>
  </div> : '', [partyData]);

  const goToHistory = useCallback(() => {
    window.open(createPartyHistoryPageLink(+id));
  }, [id]);

  // TODO: change to "Party not found" page when created instead of redirect
  if (!id || !isPartyLoading && !pkaName?.nameValue) return <Redirect to={'/not-found'} />;

  return (
    <Layout loading={isPartyLoading || loadingDictionaries}>
      {isPartyLocked && <Banner text={lockedBannerContent} />}
      {partyData && (
        <div className={cn('container')}>
          <Subheader
            location={t('party_detail')}
            mainInfo={title}
            additionalInfo={
              <RecordChangeTimeAndAuthor
                type={'created'}
                createdAt={pkaName?.nameCreatedAt}
                createdBy={pkaName?.nameCreatedBy}
              />
            }
            rightSideBlock={
              <div className={styles.editButtonContainer}>
                {canRepublishToRAD && hasUAID &&
                  <Tooltip tooltipClassName={styles.republishButtonTooltip} place="bottom" text={t('tooltips:republish_action')}>
                    <Button
                      label={t('party_labels:republish_action')}
                      containerStyle={EButtonStyles.link}
                      disabled={publishToRadLoading}
                      icon={publishToRadLoading ? <BasicSpinner size={ESpinnerSize.SMALL}/> : 'right-left'}
                      onClick={() => publishToRadHandler(Number(id))}
                    />
                  </Tooltip>
                }
                {canViewHistory &&
                  <Tooltip className={styles.historyButtonTooltip}  place="bottom" text={t('tooltips:history_link')}>
                    <Button
                      label={t('common:history')}
                      containerStyle={EButtonStyles.link}
                      icon='clock-rotate-left'
                      onClick={goToHistory}
                    />
                  </Tooltip>
                }
                {canEditParty(partyData.party) &&
                  (isPartyLocked ?
                    <Tooltip place="bottom" text={t('tooltips:party_locked')}>
                      <Button
                        label={t('common:edit')}
                        containerStyle={EButtonStyles.outline}
                        onClick={() => h.push(createEditPartyPageLink(+id))}
                        disabled={isPartyLocked}
                        className={styles.editButton}
                        size={EButtonSizes.mini}
                      />
                    </Tooltip>
                    : <Button
                      label={t('common:edit')}
                      containerStyle={EButtonStyles.outline}
                      size={EButtonSizes.mini}
                      className={styles.editButton}
                      onClick={() => h.push(createEditPartyPageLink(+id))}
                    />)}
              </div>
            }
            sticky />
          <PartyInfo party={partyData} />
        </div>
      )}
    </Layout>
  );
};

