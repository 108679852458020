import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Dialog, EDialogType } from '@grow-components/Popup';
import { DELETE_IDS } from 'graphql/mutations';
import { EIdentifierType, Identifier } from 'modules/common/types/partyTypes';
import { tMessage } from 'modules/common/helpers/translation';
import { GET_NAME_BY_ID } from 'graphql/queries';
import { EIsniPageCameFrom } from '../../types/isniTypes';
import { EButtonSizes, EButtonStyles } from '../../types/legatoTypes';
import { ToastService } from 'utils/ToastService';
import { Tooltip } from '@grow-components/Tooltip';
import { Button } from '@wmgtech/legato';


interface IIsniDeleteProps {
  nameId: number;
  name: string;
  page?: EIsniPageCameFrom;
  identifiers?: Identifier[]
  removeIsni?: () => void
}

export const IsniDelete: FC<IIsniDeleteProps> = ({
  nameId,
  name,
  page,
  identifiers,
  removeIsni
}) => {
  const { t } = useTranslation();
  const [ids, setIds] = useState<Identifier[]>(identifiers || []);
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal]);

  const [getNameById] = useLazyQuery(GET_NAME_BY_ID, {
    variables: {
      data: {
        nameId
      }
    },
    onCompleted: (data) => {
      setIds(data.nameGetById.name.identifiers);
    }
  });

  const [deleteIds, { loading }] = useMutation(DELETE_IDS, {
    variables: {
      data: {
        nameId
      }
    }
  });

  const removeHandler = useCallback(() => {
    removeIsni && removeIsni();
    setShowModal(false);
    if (page === EIsniPageCameFrom.SEARCH) {
      ToastService.success(<Trans
        i18nKey="toasts:isni_has_been_removed"
        values={{ name: name }}
      />);
    }
  }, []);

  const handleRemove = useCallback(() => {
    if (page === EIsniPageCameFrom.FORM) {
      removeHandler();
    } else
      deleteIds().then(removeHandler);
  }, []);

  useEffect(() => {
    if (!identifiers) getNameById();
  }, [identifiers]);

  const idsList = useMemo(() => {
    if (!ids || !ids.length) return null;

    return ids.filter(id => id.identifierType !== EIdentifierType.ISNI && id.identifierType !== EIdentifierType.UAID && id.identifierType !== EIdentifierType.MERGED_ISNI)
      .map(filteredId => tMessage(filteredId.identifierType)).join(', ');
  }, [ids]);

  return <>
    <Tooltip text={t('tooltips:delete_isni')}>
      <Button label={t('delete_isni')} containerStyle={EButtonStyles.link} colorType={'danger'} secondaryIcon={'trash-alt'} size={EButtonSizes.mini} onClick={toggleModal} />
    </Tooltip>
    <Dialog
      name={t('delete_isni')}
      isOpen={showModal}
      type={EDialogType.destructive}
      onClose={toggleModal}
      onAction={handleRemove}
      cancelText={t('cancel')}
      actionText={t('delete_isni')}
      loading={loading}
    >
      Are you sure you want to delete the ISNI for {name}? {idsList && <>This will also remove the following
      ID’s: <b>{idsList}</b>.</>}
    </Dialog>
  </>;
};
