import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FormSpy, useForm } from 'react-final-form';
import { v4 as uuidV4 } from 'uuid';
import { Trans, useTranslation } from 'react-i18next';
import { EIdentifierType, ENamePrivacyType, ERemarkType, Identifier, Party, Remark } from 'modules/common/types/partyTypes';
import { TPartyName, ENameType } from 'domains/party/types';
import RemarksContainer from './RemarksContainer';
import { IdentifiersInfo } from 'modules/pages/PartyPage/components/Identifier';
import { IsniLookup } from 'modules/common/components/isniLookup/IsniLookup';
import { EEventType, useEventListener } from 'modules/common/hooks/useEventListener';
import { Button } from '@wmgtech/legato';
import { EButtonStyles, EButtonSizes } from 'modules/common/types/legatoTypes';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { LegatoFFSelect } from '@grow-components/Form';
import { BROADCAST_CHANNELS, useBroadcastChannel, } from 'modules/common/hooks/useBroadcastChannel';
import { TNameDuplicationResult } from 'graphql/queries';
import { EStorageKeys } from 'common/consts';
import { PrivacyMode } from './PrivacyMode';
import { RecordChangeTimeAndAuthor } from '@grow-components/RecordChangeTimeAndAuthor/RecordChangeTimeAndAuthor';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import { PERFORMING_ARTIST_COMPETENCY_ID } from 'utils';
import { Translation } from './Translation';
import { Competencies } from './Competencies';
import { EIsniPageCameFrom } from 'modules/common/types/isniTypes';
import { NameField, THiddenNameFields } from './Name';
import { EQueryType } from './checkNames';
import { ToastService } from 'utils/ToastService';
import { getKeyboardFocusableElements } from 'utils/focus';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { LegalName } from './LegalName';
import { useConfirmation, actionCompose } from '@grow-components/Popup';
import { MajorGenre, MinorGanre } from './Genre';
import { isPerformingArtist } from '../helpers';
import { convertDateToNumbers } from 'modules/common/helpers/dateHelpers';
import styles from './NameModule.module.scss';

interface Props {
  nameIndex: number;
  onNameDelete: ((idx: number) => void) | undefined;
  onAddName: () => void;
  disabled?: boolean;
  onNameChanged: (name: string, nameIndex: number, fromCompetency?: boolean) => void;
  onLegalNameToggle: (data: { nameIndex: number, checked: boolean }) => void;
  isNameValidating?: boolean;
  fuzzyMatchResults?: TNameDuplicationResult[];
  exactMatchResult?: TNameDuplicationResult[];
  isExpanded: boolean;
  isRepeated: number;
  onExpandToggle: (newExpandToggle: boolean, idx: number) => void;
  isEdit?: boolean;
  onDeleteNameMatch (type?: EQueryType): void;
}

export type TNameModuleValues = TPartyName & THiddenNameFields

export const NameModule: React.FC<Props> = ({
  nameIndex,
  onNameDelete,
  onNameChanged,
  onLegalNameToggle,
  isNameValidating,
  fuzzyMatchResults,
  exactMatchResult,
  isExpanded,
  onExpandToggle,
  disabled,
  isRepeated,
  isEdit,
  onDeleteNameMatch,
}) => {

  const namePath = `names[${nameIndex}]`;
  const { t } = useTranslation('party_labels');
  const { t: tTooltip } = useTranslation('tooltips');
  const { dictionaries, loading: dataLoading, getCountryByISO } = useDictionaries();
  const form = useForm();
  const { channel } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI_ASSIGN);
  const { values } = form.getState();
  const currentNameValues: TPartyName & THiddenNameFields = values.names[nameIndex];
  const [isniAssigned, setIsniAssigned] = useState(false);
  const [initialPrivacyType] = useState(values.names[nameIndex].privacyType);
  const { canEditPerfArtistCompetency, canRemovePerfArtistCompetency, manageTranslations, canDeleteName } = usePermissions();
  let initialValues: TPartyName & THiddenNameFields;

  if(Object.keys(currentNameValues).length === 1) return null;

  useEffect(() => {
    currentNameValues.remarks?.push({ text: '' } as Remark);
  }, []);

  useEffect(() => {
    initialValues = currentNameValues;
  }, []);


  const storageListener = (e: StorageEvent) => {
    const { names } = values;
    const currentNameId = currentNameValues.nameId;
    let targetNameId = -1;
    if (names) targetNameId = names[nameIndex]?.nameId || -1;
    if (e.key === `isniData_${currentNameId}` && e.newValue && e.newValue !== e.oldValue && targetNameId === currentNameId) {
      localStorage.removeItem(EStorageKeys.namePrivacy);
    }
  };

  useEventListener(EEventType.storage, storageListener);

  const additionalIdsList = useMemo<Identifier[]>(
    () => {
      const { identifiers } = form.getState().values.names[nameIndex];
      if (isniAssigned) setIsniAssigned(false);

      if (typeof identifiers === 'undefined') return [];

      const filteredIdentifiers: Identifier[] = identifiers.filter((f: Identifier) => f.identifierType !== EIdentifierType.UAID && f.identifierType !== EIdentifierType.ISNI && f.identifierType !== EIdentifierType.MERGED_ISNI);
      const orderedIdentifiers = filteredIdentifiers.sort((a,b) => {
        //if the identifiers are equal, to sort by value
        const key: keyof Identifier = a.identifierType !== b.identifierType ? 'identifierType' : 'value';
        return a[key] > b[key] ? 1 : -1;
      });

      return [
        //put UAID identifier first, done with a filter method because the find method give errors in parties without UAID
        ...identifiers.filter((f: Identifier) => f.identifierType === EIdentifierType.UAID),
        ...orderedIdentifiers
      ];
    },
    [isniAssigned, currentNameValues],
  );
  const messageHandler = useCallback(
    (message) => {
      const { nameId, identifiers, isniRemark, personalInfo } = message;
      const currentName = form.getState().values.names[nameIndex];
      const { dateOfBeginningYear, dateOfBeginningMonth, dateOfBeginningDay, dateOfEndYear, dateOfEndMonth, dateOfEndDay, countryOfOriginId } = values;
      const isni = identifiers.find((identifier: Identifier) => identifier.identifierType === EIdentifierType.ISNI);
      const isniCountry = personalInfo && getCountryByISO(personalInfo.nationality);
      const birthDate = personalInfo && convertDateToNumbers(personalInfo.birthDate, 'yyyy-mm-dd');
      const birthDay = personalInfo && birthDate.day;
      const birthMonth = personalInfo && birthDate.month;
      const birthYear = personalInfo && birthDate.year;
      const deathDate = personalInfo && convertDateToNumbers(personalInfo.deathDate, 'yyyy-mm-dd');
      const endDay = personalInfo && deathDate.day;
      const endMonth = personalInfo && deathDate.month;
      const endYear = personalInfo && deathDate.year;
      const returnValueIfExists = (validator: any, valueToReturn: any) => {
        return !!validator && valueToReturn;
      };
      const currentNameIdentifiers = currentNameValues?.identifiers?.filter((itnd: any )=> itnd.identifierType !== EIdentifierType.MERGED_ISNI);
      let quansicRegister: string[] = [];
      if (nameId === currentName._formNameId) {
        if (currentName.nameType === 'PKA') {
          //Nationality
          if (!countryOfOriginId && personalInfo.nationality) {
            form.change('countryOfOriginId', isniCountry?.countryId);
            quansicRegister.push('party.countryOfOriginId');
          }
          //DOB
          if (!dateOfBeginningYear && personalInfo && personalInfo.birthDate) {
            form.change('dateOfBeginningDay', birthDay);
            form.change('dateOfBeginningMonth', birthMonth);
            form.change('dateOfBeginningYear', birthYear);
            quansicRegister = [...quansicRegister, 
              returnValueIfExists(birthDay,'party.dateOfBeginningDay'), 
              returnValueIfExists(birthMonth,'party.dateOfBeginningMonth'), 
              'party.dateOfBeginningYear'];
          }
          
          if((dateOfBeginningYear === birthYear) && (!dateOfBeginningMonth || !dateOfBeginningDay) && (birthMonth || birthDay)) {
            form.change('dateOfBeginningDay', birthDay);
            form.change('dateOfBeginningMonth', birthMonth);
            quansicRegister = [...quansicRegister, returnValueIfExists(birthDay,'party.dateOfBeginningDay'), returnValueIfExists(birthMonth,'party.dateOfBeginningMonth')];
          }
          //DOD
          if (!dateOfEndYear && personalInfo && personalInfo.deathDate) {
            form.change('dateOfEndDay', endDay);
            form.change('dateOfEndMonth', endMonth);
            form.change('dateOfEndYear', endYear);
            quansicRegister = [
              ...quansicRegister,
              returnValueIfExists(endDay, 'party.dateOfEndDay'),
              returnValueIfExists(endMonth, 'party.dateOfEndMonth'),
              'party.dateOfEndYear',
            ];
          }
          if ((dateOfEndYear === endYear) && (!dateOfEndMonth || !dateOfEndDay) && (endMonth || endDay)) {
            form.change('dateOfEndDay', endDay);
            form.change('dateOfEndMonth', endMonth);
            quansicRegister = [
              ...quansicRegister,
              returnValueIfExists(endDay, 'party.dateOfEndDay'),
              returnValueIfExists(endMonth, 'party.dateOfEndMonth'),
            ];
          }
        }
        //Remarks
        if (isniRemark) {
          form.change(`${namePath}.remarks` as any, [isniRemark,...(currentNameValues.remarks?.filter(item => item.remarkType !== ERemarkType.ADDITIONAL_INFO) || [])]);
          quansicRegister = [...quansicRegister, 'remarks[?(@.remarkType==\'ADDITIONAL_INFO\')]'];
        }
        else form.change(`${namePath}.remarks` as any,
          currentNameValues?.remarks?.filter(r => r.remarkType !== ERemarkType.ISNI_DISAMBIGUATION_COMMENT && r.remarkType !== ERemarkType.ADDITIONAL_INFO));

        //Identifiers
        const UAID = currentNameIdentifiers?.filter(item => item.identifierType === EIdentifierType.UAID) || [];
        const idsAddedByUser = currentNameIdentifiers?.filter(id => (!id.updatedBy || !id.updatedBy?.toLowerCase().includes('isni')) && (id.identifierType !== EIdentifierType.UAID)) || [];
        // here we also remove the ones added by the user
        const importedIds = identifiers.filter((id: Identifier) => !idsAddedByUser.some(userAddedId => userAddedId.identifierType === id.identifierType));

        form.change(`${namePath}.identifiers` as any, [...(UAID), ...idsAddedByUser, ...importedIds]);

        for (let i = 0; i < importedIds.length; i++) {
          if ((importedIds[i].identifierType !== EIdentifierType.ISNI) && (importedIds[i].identifierType !== EIdentifierType.MERGED_ISNI)) {
            quansicRegister.push(`identifiers[?(@.identifierType=='${importedIds[i].identifierType}')]`);
          }
        }
        form.change(`${namePath}._mapOfChangeAuthors`, quansicRegister.filter(item => !!item));
        setIsniAssigned(true);
        
        ToastService.info(
          <Trans
            i18nKey="toasts:isni_selected"
            values={{ isni: formatISNIStatic(isni.value), name: currentName.nameValue }}
          />);
      }
    }, [isniAssigned, values],
  );

  useEffect(() => {
    channel.addEventListener('message', messageHandler);
    return () => {
      channel.removeEventListener('message', messageHandler);
    };
  }, [messageHandler]);

  const removeIsni = useCallback(() => {
    const { remarks, identifiers } = form.getState().values.names[nameIndex];
    form.change(`${namePath}.remarks` as any,
      remarks.filter((r: Remark) => (r.remarkType !== ERemarkType.ADDITIONAL_INFO && r.remarkType !== ERemarkType.ISNI_DISAMBIGUATION_COMMENT) ) ?? []);
    form.change(`${namePath}.identifiers` as any, identifiers.filter((id: Identifier) => id.identifierType === EIdentifierType.UAID));
    form.change(`${namePath}._mapOfChangeAuthors`, []);
    setIsniAssigned(true);
  }, []);

  const registerHiddenFields = () => {
    form.registerField(
      `${namePath}._fuzzyMatchesWereIgnoredOnce`,
      () => null,
      { value: true },
      {
        initialValue: !!currentNameValues.nameValue
      }
    );
    form.registerField(
      `${namePath}._fuzzyMatchesAreIgnored`,
      (state) => {
        if (state.value) onDeleteNameMatch(EQueryType.FUZZY);
        return null;
      },
      { value: true }
    );
    form.registerField(
      `${namePath}._isFuzzyMatchesShown`,
      () => null,
      { value: true }
    );
    form.registerField(
      `${namePath}._hasExactMatch`,
      (state) => !state.value ? onDeleteNameMatch() : null,
      { value: true }
    );
    form.registerField(
      `${namePath}._isExactMatchAreIgnored`,
      (state) => {
        if (state.value) onDeleteNameMatch(EQueryType.EXACT);
        return null;
      },
      { value: true }
    );
    form.registerField(
      `${namePath}._formNameId`,
      () => null,
      { value: true },
      {
        initialValue: currentNameValues?.nameId ? currentNameValues.nameId.toString() : uuidV4()
      }
    );
    form.registerField(
      `${namePath}._isRepeated`,
      () => null,
      { value: true },
      {
        initialValue: isRepeated
      }
    );
    form.registerField(
      `${namePath}._originalValues`,
      () => null,
      { value: true },
      {
        initialValue: initialValues
      }
    );
    form.registerField(`${namePath}._mapOfChangeAuthors`,
      () => null,
      { value: true },
      { initialValue: [] }
    );
  };
  useEffect(registerHiddenFields, [form]);
  useEffect(form.resumeValidation, [isExpanded]);

  const [onDeletePerformingName, dialog1] = useConfirmation(
    onNameDelete,
    () => {
      if (currentNameValues.nameType === ENameType.PKA) return false;
      if (typeof currentNameValues.nameId === 'undefined') return false;
      if (isPerformingArtist(currentNameValues)) return true;
      return false;
    }, {
      name: t('modal:delete_name_confirmation'),
      message: t('modal:delete_name_performing_artisr_confirmation_message'),
      actionText: t('common:delete'),
    });

  const [onDeleteNoCompetencyName, dialog2] = useConfirmation(
    onNameDelete,
    () => {
      if (currentNameValues.nameType === ENameType.PKA) return false;
      if (typeof currentNameValues.nameId === 'undefined') return false;
      if (!isPerformingArtist(currentNameValues)) return true;
      return false;
    }, {
      name: t('modal:delete_name_confirmation'),
      message: t('modal:delete_name_confirmation_message'),
      actionText: t('common:delete'),
    });

  const onDeleteName = actionCompose(
    onNameDelete,
    onDeleteNoCompetencyName,
    onDeletePerformingName,
  );

  const deleteButton = useMemo(() => {
    if (typeof onNameDelete !== 'function') return undefined;
    const isDisabled = values.names.length <= 1 || !canDeleteName;
    return (
      <Tooltip text={tTooltip('delete_name')}>
        <Button
          size={EButtonSizes.small}
          containerStyle={EButtonStyles.outline}
          label={t('party_labels:delete_name')}
          icon="trash-can"
          onClick={() => onDeleteName(nameIndex)}
          disabled={isDisabled}
        />
      </Tooltip>
    );
  }, [values, onNameDelete]);
  const handleToggleCollapse = useCallback(() => {
    onExpandToggle(!isExpanded, nameIndex);
  }, [isExpanded]);

  const isCompetencyDisabled = useCallback((idx: number) => {
    if (!isEdit || !currentNameValues.competencies || canEditPerfArtistCompetency && canRemovePerfArtistCompetency)
      return disabled;
    return disabled || +currentNameValues.competencies[idx]?.competencyId === PERFORMING_ARTIST_COMPETENCY_ID;
  }, [currentNameValues.competencies, currentNameValues.nameType, currentNameValues.isLegal]);

  const renderNameField = useMemo(() => (
    <NameField
      exactMatchResult={exactMatchResult}
      fuzzyMatchResults={fuzzyMatchResults}
      nameIndex={nameIndex}
      isExpanded={isExpanded}
      onNameChanged={onNameChanged}
      isNameValidating={isNameValidating}
      fuzzyIgnored={currentNameValues._fuzzyMatchesWereIgnoredOnce}
      isEdit={isEdit}
      disabled={disabled}
      isRepeated={isRepeated}
    />
  ), [exactMatchResult, fuzzyMatchResults, nameIndex, isExpanded, onNameChanged, isNameValidating, disabled, currentNameValues._fuzzyMatchesWereIgnoredOnce]);

  const renderIsniField = () => (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const isni: Identifier | undefined = currentNameValues.identifiers?.find((id: Identifier) =>
          id?.identifierType === 'ISNI');

        return (
          <IsniLookup
            className="form-group"
            page={EIsniPageCameFrom.FORM}
            name={values?.names[nameIndex]}
            currentIsni={isni?.value}
            removeIsni={removeIsni}
            disabled={initialPrivacyType === ENamePrivacyType.INTERNAL_TO_WMG}
          />
        );
      }}
    </FormSpy>
  );

  const ref = useRef<HTMLDivElement>();

  const nameModuleRef = useCallback((element: HTMLDivElement) => {
    ref.current = element;
  }, [ref]);

  useEffect(() => {
    if (document?.activeElement?.getAttribute('data-name') === 'add-name-btn') {
      ref.current && getKeyboardFocusableElements(ref.current)[0]?.focus();
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div id={`name_module_${nameIndex}`} className={styles.nameCard} ref={nameModuleRef}>
      {
        dialog1
      }
      {
        dialog2
      }
      {isEdit && currentNameValues?.nameId && (
        <RecordChangeTimeAndAuthor
          className={styles.changedTimestamp}
          type="edited"
          createdBy={currentNameValues.nameCreatedBy}
          createdAt={currentNameValues.nameCreatedAt}
          editedAt={currentNameValues.nameUpdatedAt}
          editedBy={currentNameValues.nameUpdatedBy}
        />
      )}
      <div className={styles.nameCard__header}>
        <div className={styles.nameCard__title}>
          <Tooltip text={tTooltip(currentNameValues.nameType.toLowerCase())}>
            {t(currentNameValues.nameType.toLowerCase())}
          </Tooltip>
          {currentNameValues.isLegal && (
            <Tooltip text={tTooltip('legal_name_pill')}>
              <span className="ml-10 badge badge-success">{t('common:legal_name')}</span>
            </Tooltip>
          )}
        </div>
        <div className={styles.nameCard__actions}>
          {currentNameValues._fuzzyMatchesWereIgnoredOnce && (
            <Tooltip text={t(`tooltips:name_level_${isExpanded ? 'collapse' : 'expand'}_button`)}>
              <Button
                containerStyle={EButtonStyles.link}
                size={EButtonSizes.mini}
                onClick={handleToggleCollapse}
                icon={isExpanded
                  ? 'compress-alt'
                  : 'expand-alt'
                }
                label={t(isExpanded ? 'common:collapse' : 'common:expand')}
              />
            </Tooltip>
          )}
          {deleteButton}
        </div>
      </div>

      <div>
        <div className="row">
          <div className="col-xs-12">
            {isExpanded && <div className="row mb-20">
              <div className="col-sm-3">
                <Field
                  forceError={true}
                  validate={(v, allV) => {
                    const values = allV as Party;
                    const pkaNames = values.names.filter(n => n.nameType === ENameType.PKA);
                    if (currentNameValues.competencies?.filter(Boolean).find((competency) => (competency.competencyId === 0 || competency.competencyId === undefined) && v === ENameType.PKA)
                        && !canEditPerfArtistCompetency
                        && !canRemovePerfArtistCompetency)
                      return 'PKA name need a competency';
                    if (pkaNames.length > 1 && v === ENameType.PKA)
                      return 'PKA name is selected somewhere else';
                  }}
                  label={t('name_type')}
                  labelTooltip={tTooltip('name_type')}
                  required={true}
                  component={LegatoFFSelect}
                  name={`${namePath}.nameType`}
                  options={[ENameType.PKA, ENameType.AKA, ENameType.FKA].map(nameType => ({
                    label: t(nameType.toLowerCase()),
                    value: nameType,
                  }))}
                  disabled={disabled}
                />
              </div>
              <div className="col-sm-3">
                <PrivacyMode
                  formName={`${namePath}.privacyType`}
                  nameValue={currentNameValues.nameValue}
                  initialType={currentNameValues.privacyType as ENamePrivacyType}
                  disabled={disabled}
                />
              </div>
            </div>}
            <div className="row">
              <div className="col-md-12 mb-20">
                {isExpanded && <LegalName
                  disabled={disabled}
                  namePath={namePath}
                  onChange={onLegalNameToggle}
                  nameIndex={nameIndex}
                />}
                {renderNameField}
              </div>
            </div>
            {currentNameValues._fuzzyMatchesWereIgnoredOnce && (
              <div>
                <div className="row">
                  <div className="col-sm-5 col-md-4 col-lg-3">
                    {renderIsniField()}
                  </div>
                  {/* Genre */}
                  {isExpanded && <>
                    <div className="col-sm-3 col-md-3 col-lg-3">
                      <MajorGenre
                        namePath={namePath}
                        dictionaries={dictionaries?.majorGenres}
                      />
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3">
                      <MinorGanre
                        namePath={namePath}
                        dictionaries={dictionaries?.majorGenres}
                        disabled={disabled}
                      />
                    </div>
                  </>}
                </div>
                {/* Identifiers */}
                {isExpanded && <>
                  <IdentifiersInfo identifiers={additionalIdsList} nameIdx={nameIndex}/>
                  {/* Competencies */}
                  <div className="mt-40">
                    <Competencies
                      namePath={namePath}
                      dictionary={dictionaries?.competencies}
                      partyType={values.partyType}
                      isCompetencyDisabled={isCompetencyDisabled}
                    />
                  </div>
                  {/* Translations */}
                  <div className="mt-40">
                    <Translation
                      disabled={disabled || !manageTranslations}
                      languages={dictionaries?.translationLanguages}
                      namePath={namePath}
                      values={currentNameValues?.translations}
                    />
                  </div>
                  <div className={'mt-40'}>
                    <RemarksContainer
                      nameIdx={nameIndex}
                      remarkTypes={dictionaries?.remarkTypes ?? []}
                      dataLoading={dataLoading}
                      isAddButtonDisabled={!currentNameValues._fuzzyMatchesWereIgnoredOnce}
                    />
                  </div>
                </>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
