import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Banner } from '@grow-components/Banner/Banner';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { OverlaySpinner } from '@grow-components/Spinners';
import { Button } from '@wmgtech/legato';
import Layout from 'modules/common/components/Layout';
import { IsniGeneralInfo } from './components/IsniGeneralInfo/IsniGeneralInfo';
import { ExpandableDataTable } from '@grow-components/ExpandableDataTable/ExpandableDataTable';
import {
  TIsniMoreInfoParams,
  TIsniMoreInfoQueryParams
} from 'modules/common/types/isniTypes';
import { assignedToDifferentParty } from 'modules/common/helpers/IsniHelpers';
import { sortNamesByNameAndLang } from './helpers';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { IsniCredit } from 'modules/common/components/IsniCredit';
import { useGetISNI } from 'domains/isni/hooks/useGetISNI';
import { mapRelationships } from 'domains/isni/utils';
import { isrcLabels, iswcLabels, namesLabels, relationshipsLabels, releaseLabels } from 'domains/isni/consts';
import { formatISNIStatic } from 'modules/common/helpers/formatISNI';
import { RequestIsniModal } from 'modules/pages/IsniSearchPage/components/RequestIsniModal/RequestIsniModal';
import { usePermissions } from 'modules/common/hooks/usePersmissions';
import {
  BROADCAST_CHANNELS,
  EBroadCastMessageType,
  useBroadcastChannel
} from 'modules/common/hooks/useBroadcastChannel';
import { ISNILookupProvider } from 'modules/common/components/ISNI';

import styles from './isni-more-info-page.module.scss';
import { createExternalLinks } from 'modules/common/helpers/createExternalLink';
import { IdentifierDrawer } from 'modules/common/components/IdentifierDrawer';
import { EButtonColors, EButtonStyles } from 'modules/common/types/legatoTypes';


export const IsniMoreInfoPage: FC = () => {
  const { t } = useTranslation();
  const { isni, nameId } = useParams<TIsniMoreInfoParams>();
  const { subscribeOnMessage } = useBroadcastChannel(BROADCAST_CHANNELS.ISNI);

  const [isIsniRequestModalVisible, setIsniRequestModalVisible] = useState(false);

  const { canRequestNewISNI } = usePermissions();

  const { params } = useQueryParams<TIsniMoreInfoQueryParams>();
  const partyId = params.partyId;

  const { loading, data: isniData } = useGetISNI(isni);

  const isniAssignedToDifferentParty = useMemo(() => assignedToDifferentParty(isniData, partyId), [isniData]);

  const navigateToParty = useCallback(() =>
    window.open(`/party/${isniData?.assignedNames[0].partyId}`), [isniData]);

  const renderTables = useCallback(() => {
    if (!isniData) return null;
    const { nameVariants, isrcContributions, iswcContributions, relationships, releaseContributions } = isniData;
    
    return (
      <>
        {nameVariants.length > 0 &&
          <ExpandableDataTable
            title="Names"
            titleTooltip={t('tooltips:isni_detail_page_names')}
            labels={namesLabels}
            data={sortNamesByNameAndLang(nameVariants)} />}
        {releaseContributions.length > 0 &&
          <ExpandableDataTable
            title="Releases"
            titleTooltip={t('tooltips:releases_isni_detail_page')}
            labels={releaseLabels}
            data={releaseContributions} />}
        {isrcContributions.length > 0 &&
          <ExpandableDataTable
            title="Recordings"
            titleTooltip={t('tooltips:sound_recording_information')}
            labels={isrcLabels}
            data={isrcContributions} />
        }
        {iswcContributions.length > 0 && (
          <ExpandableDataTable
            title="Works"
            titleTooltip={t('tooltips:works_information')}
            labels={iswcLabels}
            data={iswcContributions} />
        )}
        {relationships.length > 0 && (
          <ExpandableDataTable
            title="Relationships"
            titleTooltip={t('tooltips:relationship')}
            labels={relationshipsLabels}
            data={mapRelationships(relationships)} />
        )}
      </>
    );
  }, [isniData]);

  const itemsToRender = useMemo(() => {
    const partyItems = [];
    if(isniData?.identifiers === undefined) return [];
    for (const [id_key, v] of Object.entries(isniData.identifiers)) {
      const { label, link } = createExternalLinks(id_key, v);
      partyItems.push({ title: label, value: v, link: link ? link : '' });
    }
    return partyItems.filter(item => item.title !== 'ISNI');
  }, [isniData]);

  const handleClose = useCallback(
    (id: string) => {
      if(id === nameId) {
        setTimeout(() => window.close(), 200);
      }
    },
    [nameId],
  );

  useEffect(() => {
    const unsub = subscribeOnMessage(EBroadCastMessageType.ASSIGNING_INTERRUPTED, handleClose);
    return () => {
      unsub();
    };
  }, [handleClose, subscribeOnMessage]);
  
  if (!loading && !isniData) {
    return <Redirect to={'/not-found'} />;
  }

  return <Layout>
    {loading && <OverlaySpinner />}
    {isniAssignedToDifferentParty && (
      <Banner text={<Button
        className={styles.button}
        icon="angle-right"
        label={t('isni_currently_assigned')}
        containerStyle={EButtonStyles.link}
        onClick={navigateToParty} />}
      />
    )}
    {isniData && (
      <div className="container">
        <Subheader
          location="ISNI Details"
          mainInfo={isniData.name}
          additionalInfo={<div>ISNI  <span className={styles.isniValue}>{formatISNIStatic(isniData.isni)}</span></div>}
          rightSideBlock={
            <div className={styles.headerActions}>
              <Button
                containerStyle={EButtonStyles.link}
                colorType={EButtonColors.primary}
                label={t('request_isni')}
                onClick={() => setIsniRequestModalVisible(true)}
                disabled={!canRequestNewISNI}
              />
              <IsniCredit bold />
            </div>
          }
          sticky />
        <ISNILookupProvider>
          <IsniGeneralInfo isniData={isniData} assignedToDifferentParty={isniAssignedToDifferentParty} />
        </ISNILookupProvider>
        <IdentifierDrawer items={itemsToRender} />
        {renderTables()}
        {isIsniRequestModalVisible && (
          <RequestIsniModal
            partyId={+partyId}
            nameId={+nameId}
            onClose={() => setIsniRequestModalVisible(false)}
            requestFromDetails
          />
        )}
      </div>
    )}
  </Layout>;
};