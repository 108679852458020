import React, { FC } from 'react';
import { cn } from 'utils';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { EDialogType, IDialogProps } from '../types';
import styles from './dialog.module.scss';
import { Button, Modal } from '@wmgtech/legato';
import { EButtonStyles } from 'modules/common/types/legatoTypes';

const getIconNameByDialogType = (type: EDialogType) => {
  switch (type) {
  case EDialogType.success:
    return 'check';
  case EDialogType.confirm:
    return 'info-circle';
  case EDialogType.destructive:
    return 'circle-exclamation';
  case EDialogType.warning:
    return 'triangle-exclamation';
  }
};

const buttonColor = (type: EDialogType) => {
  switch (type) {
  case EDialogType.success:
    return 'success';
  case EDialogType.confirm:
    return 'primary';
  case EDialogType.destructive:
    return 'danger';
  case EDialogType.warning:
    return 'warning';
  }
};


export const Dialog: FC<IDialogProps> = ({
  loading,
  type,
  actionText,
  cancelText,
  closeText,
  onAction,
  onClose,
  onCancel,
  name,
  isOpen,
  children
}) => {

  return isOpen ? (
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      title={name}
      icon={getIconNameByDialogType(type)}
      className={cn(styles.headerModal, styles[`headerModal-${type}`])}
    >
      <div className={styles.content}>{children}</div>
      {
        loading ? (
          <BasicSpinner className={styles.spinner} />
        ) : null
      }
      <div className={cn(styles.buttons, styles[type])}>
        {closeText && 
        <Button label={closeText} onClick={onClose} containerStyle={EButtonStyles.link} colorType={'info'}/>}
        <Button label={cancelText} onClick={onCancel || onClose} containerStyle={EButtonStyles.outline} colorType={'info'}/>
        <Button label={actionText} onClick={onAction} containerStyle={EButtonStyles.solid} colorType={buttonColor(type)}/>
      </div>
    </Modal>
  ) : null;
};
