import React, { FC, ReactNode, useCallback } from 'react';
import styles from './GenericModal.module.scss';
import { Modal } from '@wmgtech/legato';

export type TGenericModalProps = {
  name: string
  title: ReactNode
  footer?: ReactNode
  onClose?: () => void
}

export const GenericModal: FC<TGenericModalProps> = (props) => {
  const { onClose } = props;
  const closeButtonHandler = useCallback(() => onClose && onClose(), [onClose]);
  return (
    <Modal
      title={'Request ISNI'}
      onClose={closeButtonHandler}
      shouldCloseOnOverlayClick={false}
      isOpen={true}
      className={styles.container}
    >
      <div className={styles.content}>
        {props.children}
      </div>
      <div className={styles.footer}>
        {props.footer}
      </div>
    </Modal>
  );
};
