import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import { createPartyHistoryPageLink } from 'utils';
import { formatPartyType } from 'modules/common/helpers/partyHelpers/formatPartyTypes';
import { ENameType } from 'domains/party/types';
import { EPartyType, partyTypes } from 'modules/common/types/partyTypes';
import {
  createDefaultName,
  fieldSubscription
} from 'modules/common/components/forms/CreateEditPartyForm/utils/formValues';
import { NameModule } from './formModules/NameModule';
import { useLigalNameConfirmation } from './useLigalNameConfirmation';
import { useDictionaries } from 'modules/common/hooks/useDictionaries';
import { LegatoFFSelect } from '@grow-components/Form';
import { Button } from '@wmgtech/legato';
import { BasicSpinner } from '@grow-components/Spinners/BasicSpinner';
import { BusinessLogic, usePartyFormModified } from './businessLogic';
import { getIgnoredPath, useCheckNames } from './formModules/checkNames';
import { RecordChangeTimeAndAuthor } from '@grow-components/RecordChangeTimeAndAuthor/RecordChangeTimeAndAuthor';
import { Tooltip } from '@grow-components/Tooltip/Tooltip';
import { Dialog, EDialogType, ModalPortal as Overlay } from '@grow-components/Popup';
import { Prompt } from './Prompt';
import { ItemType } from './ItemType';
import { Icon } from '@grow-components/Icon/Icon';
import { Subheader } from '@grow-components/Subheader/Subheader';
import { TFormProps } from './types';
import { PartyDateInputGroup } from '@grow-components/PartyDateInputGroup/PartyDateInputGroup';
import { useSettings } from 'domains/env/hooks';
import { countryOptions, languageOptions } from 'modules/common/helpers/forms/frecuentOptionsFormat';
import { Checkbox } from '@wmgtech/legato';
import { ESpinnerSize } from '@grow-components/Spinners/consts';
import SaveDropdown from './formModules/saveDropdown';
import { EButtonHTMLTypes, EButtonSizes, EButtonStyles } from 'modules/common/types/legatoTypes';
import styles from 'modules/pages/Party/CreateEditPartyForm.module.scss';

export const PartyForm: FC<TFormProps> = (props) => {
  const { handleSubmit, values, isEdit, form, submitting, aborting } = props;
  const { t } = useTranslation('party_labels');
  const { t: tTooltip } = useTranslation('tooltips');
  const { loading: loadingDictionaries, dictionaries } = useDictionaries();
  const { settings } = useSettings();

  const {
    validateNames,
    handleDelete: handleDeleteNameErrors,
    handleClean: handleDeleteMatch,
    isValidating: isValidatingNames,
    fuzzyResults,
    exactResults,
  } = useCheckNames();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [lastSelectedPKA, setLastSelectedPKA] = useState<number | null>(null);
  const [repeat, setRepeat] = useState(-1);
  const lastLength = useRef<number>(values.names.length);
  const partyFormModified = usePartyFormModified(props.init);
  const checkNames = useCallback((lastChangedNameIndex?: number, fromCompetencyArtist?: boolean) => {
    const { names, partyType, countryOfOriginId, id } = form.getState().values;
    validateNames(lastChangedNameIndex, { names, partyType, countryOfOriginId, id, excludeFuzzyValidation: fromCompetencyArtist });
  }, [form, validateNames]);

  const cleanIgnore = useCallback(
    () => {
      const { names } = form.getState().values;
      const paths = getIgnoredPath(names);
      form.batch(() => {
        // eslint-disable-next-line
        // @ts-ignore
        paths.forEach(path => form.change(path, undefined));
      });
    },
    [form],
  );

  const handlePartyTypeChange = useCallback((value: EPartyType) => {
    if (value === EPartyType.PERSON) form.change('partyType', EPartyType.PERSON);
    if (value === EPartyType.GROUP) form.change('partyType', EPartyType.GROUP);
    form.change('partySubType', undefined);
    cleanIgnore();
    checkNames();
  }, [form]);

  const handleCountryOfOriginChange = useCallback((val: number) => {
    if (!val || val === values.countryOfOriginId) return form.change('countryOfOriginId', undefined);
    form.change('countryOfOriginId', val);
    cleanIgnore();
    checkNames();
  }, [form]);

  const handleLanguageOfOriginChange = useCallback((val: number) => {
    if (!val || val === values.languageOfOriginId) return form.change('languageOfOriginId', undefined);
    form.change('languageOfOriginId', val);
  }, [form]);

  const getTitleByPartyType = useCallback((forPerson: string, forGroup: string) => {
    const { partyType } = form.getState().values;
    if (partyType === EPartyType.PERSON) return forPerson;
    if (partyType === EPartyType.GROUP) return forGroup;
    console.error('Unknown party type: ' + partyType);
    return '';
  }, [form]);

  const name = useMemo(() => lastSelectedPKA !== null ? values.names?.[lastSelectedPKA]?.nameValue : values.names[0].nameValue,
    [lastSelectedPKA, values]);

  const getPageTitle = useCallback(() => {
    if (isEdit) {
      return t('edit_party');
    } else {
      return <>
        {t('common:create_party')}
        <Tooltip place="right" tooltipClassName={styles.helperTooltip} text={<>
          <span className={styles.helperHeading}><Icon icon="circle-info" />&nbsp;{t('tooltips:party_creation_heading')}</span>
          <div>{t('tooltips:party_creation_msg')}</div>
        </>}>
          <Icon icon="circle-info-solid" className={styles.helperIcon} />
        </Tooltip>
      </>;
    }
  }, [isEdit, values.partyType, values.names, lastSelectedPKA]);

  const goToHistory = useCallback(() => {
    window.open(createPartyHistoryPageLink(values.id ? +values.id : 0));
  }, [values.id]);

  const onCancelConfirmHandler = useCallback(() => {
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  }, [props.onCancel]);

  const onCancelGoBackHandler = useCallback(() => {
    setShowCancelModal(false);
  }, [partyFormModified, setShowCancelModal]);

  const onCancelHandler = useCallback(() => {
    if (partyFormModified) {
      setShowCancelModal(true);
    } else {
      onCancelConfirmHandler();
    }
  }, [partyFormModified, onCancelConfirmHandler, setShowCancelModal]);

  const [confirmatedToggleLegal, dialog] = useLigalNameConfirmation();

  const changeRepeatFormValue = (nameIndex: number) => {
    const currentNames = form.getState().values.names;
    currentNames.forEach((item, index) =>{
      form.change(`names[${index}]._isRepeated` as any, -1);
    });
    form.change(`names[${nameIndex}]._isRepeated` as any, nameIndex);
    return setRepeat(nameIndex);
  };

  useEffect(() => {
    const repeatedValues: number[] = [];
    values.names.forEach((item, index) => {
      values.names.forEach((item2, index2) => {
        if (index !== index2)
          if (item.nameValue === item2.nameValue)
            repeatedValues.push(index);
      });
    });
    if (repeatedValues.length === 0) changeRepeatFormValue(-1);
  }, [repeat, checkNames]);

  const handleNameChange = useCallback((_, nameIndex: number, fromCompetencyChange?: boolean) => {
    const currentNames = form.getState().values.names;
    const { nameType, competencies } = values.names[nameIndex];
    const repeated = currentNames.find((item, index) =>index !== nameIndex && item.nameValue === currentNames[nameIndex].nameValue);
    if(repeated) return changeRepeatFormValue(nameIndex);
    changeRepeatFormValue(-1);
    if (nameType === ENameType.PKA && competencies?.[0].competencyId !== 0) {
      setLastSelectedPKA(nameIndex);
    }
    checkNames(nameIndex, fromCompetencyChange || false);
  }, [checkNames, repeat, values.names]);

  const [nameExpandState, setNameExpandState] = useState<boolean[]>(
    values.names.map((name, idx) => name.nameType === ENameType.PKA || idx === 0)
  );

  useEffect(() => {
    if (lastLength.current > values.names.length) {
      setNameExpandState(values.names.map((name, idx) => name.nameType === ENameType.PKA || idx === 0));
    }
    if (lastLength.current < values.names.length) {
      setNameExpandState(values.names.map((name, idx) => name.nameType === ENameType.PKA || idx === values.names.length - 1));
    }
    lastLength.current = values.names.length;
  },[values.names.length]);

  const handleToggleExpand = useCallback((expand: boolean, idx?: number) => {
    form.pauseValidation();
    setNameExpandState(expandStates => {
      const newExpandStates = expandStates.slice();
      if (typeof idx !== 'undefined') {
        newExpandStates[idx] = expand;
        return newExpandStates;
      }
      return newExpandStates.map(() => expand);
    });
  }, []);

  useEffect(form.resumeValidation, [nameExpandState]);
  const handleExpandAll = useCallback(() => handleToggleExpand(true), []);
  const handleCollapseAll = useCallback(() => handleToggleExpand(false), []);
  const handleAddName = useCallback(() => {
    form.mutators.push('names', createDefaultName(ENameType.AKA));
  }, [setNameExpandState, nameExpandState]);

  const handleDeleteName = useCallback((nameId: number) => {
    form.mutators.remove('names', nameId);
    handleToggleExpand(false, nameId);
    handleDeleteNameErrors(nameId);
  }, []);

  window.onscroll = () => {
    const collection = document.getElementsByClassName('lgt-trigger');
    if(collection.length){
      const drop = collection[0] as HTMLElement;
      drop.style.top = '128px';
      drop.style.position = 'fixed';
    }
  };


  const pkaName = values.names[0];
  const countryOptionsArray = countryOptions(settings?.lastUsedCountryOfOrigin, dictionaries);
  const languageOptionsArray = languageOptions(settings?.lastUsedLanguageOfOrigin, dictionaries);

  const actions = <div className='flex'>
    <Tooltip text={tTooltip('expand_all')} place="bottom">
      <Button
        className={styles.linkButton}
        containerStyle={EButtonStyles.link}
        onClick={handleExpandAll}
        icon={'expand-alt'}
        label={t('common:expand_all')}
      />
    </Tooltip>
    <Tooltip text={tTooltip('collapse_all')} place="bottom">
      <Button
        className={styles.linkButton}
        containerStyle={EButtonStyles.link}
        onClick={handleCollapseAll}
        icon={'compress-alt'}
        label={t('common:collapse_all')}
      />
    </Tooltip>
    <Tooltip place="bottom" text={t('tooltips:history_link')}>
      <Button
        className={styles.linkButton}
        label={t('common:history')}
        containerStyle={EButtonStyles.link}
        icon='clock-rotate-left'
        onClick={goToHistory}
      />
    </Tooltip>
    <Button
      className={styles.linkButton}
      containerStyle={EButtonStyles.link}
      onClick={onCancelHandler}
      label={t('common:cancel')}
    />
    <FormSpy subscription={{ pristine: true, submitting: true, errors: true }}>
      {({ submitting }) => {
        return (
          <div className={styles.saveButtonDiv}>
            <Button
              htmlType={EButtonHTMLTypes.submit}
              onClick={()=>{form.change('saveType', false); form.submit();}}
              disabled={submitting || isValidatingNames}
              containerStyle={EButtonStyles.outline}
              size={EButtonSizes.mini}
              className={styles.saveButton}
            >
              {submitting ? <BasicSpinner size={ESpinnerSize.SMALL} className={styles.spinner} /> : t('common:save')}
            </Button>
            <SaveDropdown onSelect={()=>{form.change('saveType', true); form.submit();}} list={[
              {
                'key': '1',
                'title': 'Save + View'
              }
            ]} />
          </div>
        );
      }}
    </FormSpy>
  </div>;

  return (
    <form onSubmit={handleSubmit} id="edit-party-form">
      {isEdit ? (
        <Subheader
          location={getPageTitle()}
          mainInfo={
            <Tooltip
              text={t('tooltips:header_level_name')}
              place={'bottom'}
              className={styles.helperTooltip}
            >{name}</Tooltip>}
          additionalInfo={<RecordChangeTimeAndAuthor
            type="created"
            createdAt={pkaName?.nameCreatedAt}
            createdBy={pkaName?.nameCreatedBy}
          />}
          rightSideBlock={actions}
          className={styles.subheader}
          sticky />
      ) : (
        <Subheader
          location={getPageTitle()}
          rightSideBlock={actions}
          sticky />
      )}
      <div className={styles.partyFieldsBlock}>
        {/* First Row */}
        <div className="row mb-20">
          <div className="col-xs-6 col-sm-3">
            <Field
              component={LegatoFFSelect}
              label={t('party_type')}
              className={'mb-10'}
              labelTooltip={tTooltip('party_type')}
              name="partyType"
              required={true}
              options={partyTypes.map((partyType) => ({
                label: formatPartyType(partyType),
                value: partyType,
              }))}
              onChange={handlePartyTypeChange}
            />
          </div>
          <div className="col-xs-6 col-sm-3">
            <ItemType partyType={values.partyType} dictionaries={dictionaries} isLoading={loadingDictionaries} />
          </div>
          <BusinessLogic dictionaries={dictionaries} />
          <div className="col-xs-6 col-sm-3">
            <Field
              name="countryOfOriginId"
              placeholder={t('party_placeholders:select_country')}
              component={LegatoFFSelect}
              isFilterable={true}
              required={true}
              label={t('origin_country')}
              labelTooltip={tTooltip('country_of_origin')}
              options={countryOptionsArray}
              loading={isValidatingNames}
              onChange={handleCountryOfOriginChange}
            />
          </div>
          <div className="col-xs-6 col-sm-3">
            <Field
              name="languageOfOriginId"
              component={LegatoFFSelect}
              isFilterable={true}
              placeholder={t('party_placeholders:select_language')}
              label={t('origin_language')}
              labelTooltip={tTooltip('language_of_origin')}
              options={languageOptionsArray}
              onChange={handleLanguageOfOriginChange}
            />
          </div>
        </div>
        {/* End of first row*/}

        {/*Second Row*/}
        <div className="row mb-20">
          {/* DATE OF BIRTH */}
          <div className="col-sm-6 col-md-3">
            <PartyDateInputGroup
              title={t(getTitleByPartyType('date_birth', 'date_formed'))}
              titleTooltip={tTooltip('beginning_date')}
              dayInputName={'dateOfBeginningDay'}
              monthInputName={'dateOfBeginningMonth'}
              yearInputName={'dateOfBeginningYear'}
            />
          </div>
          {/* END OF DOB*/}

          {/* DATE OF DEATH */}
          <div className="col-sm-6 col-md-3">
            <PartyDateInputGroup
              title={t(getTitleByPartyType('date_death', 'date_disbanded'))}
              titleTooltip={tTooltip('ending_date')}
              dayInputName={'dateOfEndDay'}
              monthInputName={'dateOfEndMonth'}
              yearInputName={'dateOfEndYear'}
            />
          </div>
          {/*  END OF DOD */}
          {/* GP-4211 hide signed field until further notice */}
          {typeof values.signedByWMG === 'number' ?? <div className={styles.signedDiv}>
            <span >
              {t('signed_label')}
            </span>
            <Field
              title={t('signed_checkbox')}
              type='checkbox'
              name="signedByWMG"
              component={Checkbox}
              label={t('signed_checkbox')}
              className={styles.checkboxSigned}
              checked={values.signedByWMG}
              onChange={(e: any)=>form.change('signedByWMG', e.target.checked)}
            />
          </div>}
        </div>
        {/*  End of second row*/}
      </div>
      {/*ADD NAME*/}
      <div>
        <FieldArray name="names" subscription={fieldSubscription}>
          {({ fields }) => fields.map((name, idx) => (
            <NameModule
              key={name}
              onAddName={handleAddName}
              onNameDelete={handleDeleteName}
              nameIndex={idx}
              onLegalNameToggle={confirmatedToggleLegal}
              onNameChanged={handleNameChange}
              isNameValidating={isValidatingNames}
              exactMatchResult={exactResults[idx]}
              fuzzyMatchResults={fuzzyResults[idx]}
              isExpanded={nameExpandState[idx]}
              onExpandToggle={handleToggleExpand}
              isRepeated={repeat}
              isEdit={isEdit}
              onDeleteNameMatch={type => handleDeleteMatch(idx, type)}
            />
          ))
          }
        </FieldArray>
        <div className="pt-30 pb-30">
          <Tooltip text={tTooltip('add_name')}>
            <Button
              containerStyle={EButtonStyles.link}
              label={t('add_name')}
              onClick={handleAddName}
              data-name="add-name-btn"
              icon="plus"
            />
          </Tooltip>
        </div>
      </div>
      <Dialog
        isOpen={showCancelModal}
        type={EDialogType.warning}
        name={t('common:cancel')}
        onAction={onCancelConfirmHandler}
        onClose={onCancelGoBackHandler}
        actionText={t('common:confirm')}
        cancelText={t('common:go_back')}
      >
        <div className={styles.cancelModalContent}>
          <span>{t('modal:are_you_sure_you_want_to_cancel')}</span>
          <span>{t('modal:cancel_edit_assign_isni')}</span>
        </div>
      </Dialog>
      <Prompt onSubmit={handleSubmit}  initialValues={props.init} />
      {
        dialog
      }
      {
        (submitting || aborting) && <Overlay nodeId='edit-party-form'>
          <div style={{
            position: 'fixed',
            inset: '0px',
            zIndex: 9999,
            border: 'none',
          }}/>
        </Overlay>
      }
    </form>
  );
};
