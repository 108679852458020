import React, { FC, useCallback } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { cn } from 'utils';
import { SearchParamRow } from '@grow-components/SearchParamRow/SearchParamRow';
import { useTranslation } from 'react-i18next';
import { advancedSearchOperators } from 'domains/search';
import { EAdvancedSearchOperator, TQuickSearchValues } from 'domains/search/types';
import { LegatoFFSelect, LegatoFFTextInput } from '@grow-components/Form';
import searchParamRowStyles from '@grow-components/SearchParamRow/SearchParamRow.module.scss';
import { partyTypes } from 'modules/common/types/partyTypes';
import { formatPartyType } from 'modules/common/helpers/partyHelpers/formatPartyTypes';
import { updateAdvancedSearchParams } from 'redux/reducers/advancedSearchReducer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'modules/common/hooks/useQueryParams';
import { Button } from '@wmgtech/legato';
import styles from './QuickSearch.module.scss';

type TQuickSearchProps = {
  className?: string
}

const QuickSearchForm: FC<FormRenderProps<TQuickSearchValues>> = (props) => {
  const { values, handleSubmit } = props;
  const { t } = useTranslation('party_labels');
  const { t: tSearch } = useTranslation('search');
  const partyTypesItems = partyTypes.map((partyType) => ({
    label: formatPartyType(partyType),
    value: partyType,
  }));

  const isFormValid = (values.nameValue && values.nameOperator) || values.partyType;

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.container}
    >
      <div>
        {/*Name*/}
        <SearchParamRow
          label={t('party_labels:name')}
          labelTestId='quick_search_name_label'
          controlsClassName={styles.controls}
          controls={<>
            <Field
              className={cn(searchParamRowStyles.control, searchParamRowStyles.small, styles.controls)}
              component={LegatoFFSelect}
              name="nameOperator"
              testId='quick_search_name_select'
              options={advancedSearchOperators(tSearch)}
            />
            <Field
              placeholder={t('party_placeholders:enter_name')}
              className={cn(searchParamRowStyles.control, styles.controls)}
              component={LegatoFFTextInput}
              testId='quick_search_name_input'
              autoComplete='off'
              aria-autocomplete='list'
              name="nameValue"
            />
          </>
          }
        />
        {/*Party Type*/}
        <SearchParamRow
          label={t('party_labels:party_type')}
          controlsClassName={styles.controls}
          labelTestId='quick_search_party_type_label'
          controls={
            <Field
              placeholder={t('party_placeholders:select_party_type')}
              className={cn(searchParamRowStyles.control, styles.controls)}
              component={LegatoFFSelect}
              testId='quick_search_party_type_select'
              name="partyType"
              options={partyTypesItems}
            />
          }
        />
      </div>
      <div className={styles.actions}>
        <Button disabled={!isFormValid} data-test-id='quick_search_action_button' colorType='primary' htmlType='submit'>Search</Button>
      </div>
    </form>
  );
};

export const QuickSearch: FC<TQuickSearchProps> = () => {
  const dispatch = useDispatch();
  const h = useHistory();
  const { createURL } = useQueryParams();

  const onSearchHandler = useCallback((values) => {
    dispatch(updateAdvancedSearchParams(values));
    h.push(createURL(values, '/advanced-search'));
  }, []);

  return (
    <Form<TQuickSearchValues>
      initialValues={{
        nameOperator: EAdvancedSearchOperator.EQUALS
      }}
      onSubmit={onSearchHandler}
      component={QuickSearchForm}
    />
  );
};

