import React, { FC, useCallback, useState } from 'react';
import { Button, EButtonSizes } from '@grow-components/Button';
import { DropDown, IRenderArguments } from '../../DropDown';
import { Button as LegatoButton } from '@wmgtech/legato';
import { UserProfile } from './UserProfile/';
import styles from './UserMenu.module.scss';
import { cn } from 'utils';
import { useTranslation } from 'react-i18next';
import { EButtonStyles } from 'modules/common/types/legatoTypes';

interface UserMenuProps {
  onOpen: () => void
  onLogout: () => Promise<void>
  userData: any
}

export const UserMenu: FC<UserMenuProps> = ({ onOpen, onLogout, userData }) => {
  const { t } = useTranslation();
  const [extended, setExtended] = useState(false);
  const header = useCallback(({ updateStatus, status }: IRenderArguments) => (
    <Button
      onClick={() => updateStatus(showContent => !showContent)}
      icon="bars"
      className={cn(styles.burger, {
        [styles.active]: status
      })}
      iconClassname={styles.hamgurguerIcon}
      size={EButtonSizes.large}
    />
  ), []);
  const content = useCallback(({ updateStatus }: IRenderArguments) => (
    <div className={styles.content}>
      <UserProfile
        userName={userData('userName')}
        userGroups={userData('groups')}
      />
      <div className={styles.linkContent}>
        <LegatoButton
          className={cn(styles.linkButton, styles.mdhq)}
          containerStyle={EButtonStyles.link}
          icon={extended ? 'chevron-up' : 'chevron-down'}
          onClick={() => setExtended(!extended)}
          label={'mdhq'}
        />
        {extended &&
        <div>
          <LegatoButton
            className={cn(styles.linkButton, styles.mdhqLinks)}
            label={'Searching Parties'}
            containerStyle={EButtonStyles.link}
            icon={'arrow-right'}
            onClick={() => window.open('https://wmg.continu.co/#/view/articles/60da480fe3cdaf0013eba3c0')}
          />
          <LegatoButton
            className={cn(styles.linkButton, styles.mdhqLinks)}
            label={'Creating Parties'}
            containerStyle={EButtonStyles.link}
            icon={'arrow-right'}
            onClick={() => window.open('https://wmg.continu.co/#/view/articles/60da4b9e93c8a200134dd343')}
          />
          <LegatoButton
            className={cn(styles.linkButton, styles.mdhqLinks)}
            label={'Assigning ISNIs'}
            containerStyle={EButtonStyles.link}
            icon={'arrow-right'}
            onClick={() => window.open('https://wmg.continu.co/#/view/articles/60db85b5ec88570013590727')}
          />
          <LegatoButton
            className={cn(styles.linkButton, styles.mdhqLinks)}
            label={'Glossary of Common Terms'}
            containerStyle={EButtonStyles.link}
            icon={'arrow-right'}
            onClick={() => window.open('https://wmg.continu.co/#/view/articles/60bfbba38d3f170013d5d3a2')}
          />
        </div>}
        <LegatoButton 
          className={cn(styles.linkButton, styles.knownIssuesLink)}
          label={'Known Issues'}
          containerStyle={EButtonStyles.link}
          icon={'arrow-right'}
          onClick={() => window.open('https://wmg.sharepoint.com/sites/glb.GDO/GROW-Comms/party-workspace/SitePages/Known-Issues-List.aspx')}
        />
        <LegatoButton
          className={styles.linkButton}
          containerStyle={EButtonStyles.link}
          label={'FAQ'}
          onClick={() => window.open('https://wmg.continu.co/#/view/articles/6262f02ccd6fae00131cc5ec')}
        />
      </div>
      <div className={styles.preferencesContent}>
        <Button
          className={styles.preferencesButton}
          text={'User Preferences'}
          icon='dial'
          onClick={() => {
            onOpen();
            updateStatus(false);
          }}
          iconClassname={styles.preferencesIcon}
        />
      </div>
      <Button
        className={styles.logoutButton}
        text={t('logout')}
        onClick={onLogout}
        icon='arrow-up-left-from-circle'
        iconClassname={styles.logoutIcon}
      />
    </div>
  ), [extended]);

  return (
    <DropDown
      className={styles.container}
      renderHead={header}
      renderContent={content}
    />
  );
};
