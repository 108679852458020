import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Icon,
  Modal,
  Tooltip,
} from '@wmgtech/legato';
import { useSearchAppleID } from 'domains/party/hooks/useSearchAppleID';
import { useSearchSpotifyID } from 'domains/party/hooks/useSearchSpotifyID';
import { EIdentifierType, Party, TEditableIds } from 'modules/common/types/partyTypes';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styles from '../IdentifiersInfo.module.scss';
import { SearchIdForm } from './SearchIDForm';
import { Identifier } from 'modules/common/types/partyTypes';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  ids: Identifier[];
  nameIdx: number;
};

type TValidatedIds = Record<TEditableIds, { value: string, validated: boolean }>;

const initialStateValidatedIds = {
  apple: { validated: false, value: '' },
  spotify: { validated: false, value: '' }
};

export const AddIdentifiersModal: React.FC<Props> = ({
  isOpen = false,
  onClose,
  ids = [],
  nameIdx
}) => {
  const [validatedIds, setValidatedIds] = useState<TValidatedIds>(initialStateValidatedIds);

  const form = useForm<Party>();
  const { values } = form.getState(); 
  const { t } = useTranslation();
  const { id, countryOfOriginId } = values;
  const { handler: searchAppleIDHandler, loading: searchAppleIdLoading, data: searchAppleIdData, error: searchAppleIdErr } = useSearchAppleID(
    id,
    countryOfOriginId
  );
  const { handler: searchSpotifyIDHandler, loading: searchSpotifyIdLoading, data: searchSpotifyIdData, error: searchSpotifyIdErr } = useSearchSpotifyID(
    id,
    countryOfOriginId
  );

  useEffect(() => {
    if(isOpen) {
      const editableIds = Array.from<TEditableIds>(['apple', 'spotify']);
      const editableIdsOnPartyFound = ids.filter(id => editableIds.includes(id.identifierType.toLowerCase() as any));

      const editableIdsOnPartyFormatted: TValidatedIds = editableIdsOnPartyFound.reduce((acc, curr: Identifier) => ({
        ...acc,
        [curr.identifierType.toLowerCase()]: { value: curr.value, validated: false }
      }), validatedIds);

      setValidatedIds(editableIdsOnPartyFormatted);
    }
  }, [isOpen]);

  const idValidationSetter = (name: TEditableIds, makeValid: boolean, value: string) => {
    setValidatedIds(state => ({ ...state, [name]: { value, validated: makeValid  } }));
  };

  const validIdsToAdd = useMemo(() => {
    let validIds: any[] = [];
    for (const id in validatedIds) {
      const { value } = validatedIds[id as TEditableIds];
      validIds = [...validIds, { [id]: value }];
    }

    for (const id in validatedIds) {
      const { value, validated } = validatedIds[id as TEditableIds];
      if(Boolean(value) && !validated)
        validIds = []; 
    }

    return validIds;
  }, [validatedIds]);

  const handleClose = () => {
    setValidatedIds(initialStateValidatedIds);
    onClose(); 
  };

  const addIdsToNameModule = () => {
    let formIdentifiers = values.names[nameIdx].identifiers;
    
    const newIdentifiers = validIdsToAdd.map(id => {
      const key = Object.keys(id)[0];
      const sameAsCurrent = formIdentifiers?.find(currentId => currentId?.value === id[key]);
      if(sameAsCurrent)
        return sameAsCurrent;

      return {
        value: id[key],
        identifierType: key.toUpperCase() as EIdentifierType
      }; 
    });

    formIdentifiers = formIdentifiers?.map(id => {
      const updatedIdInFormIfExists = newIdentifiers.find(newId => newId.identifierType === id?.identifierType);
      return updatedIdInFormIfExists || id;
    });

    newIdentifiers.forEach(newId => {
      const newIdNotIncluded = !formIdentifiers?.some((id: any) => id?.identifierType === newId.identifierType);

      if(newIdNotIncluded)
        formIdentifiers?.push(newId);
    });

    form.change(`names[${nameIdx}].identifiers` as any, formIdentifiers?.filter(id => id?.value));

    handleClose();
  };

  return (
    <Modal
      className={styles.addIdentifiersModal}
      size='lg'
      isOpen={isOpen}
      icon={<Icon className={styles.identifiersModalIcon} name="id-card"/>}
      onClose={handleClose}
      title={t('modal:edit_identifiers')}
      footer={
        <div style={{ display: 'flex', gap: 10, justifyContent: 'flex-end' }}>
          <Button containerStyle='link' colorType='secondary-black' onClick={handleClose}>
            {t('common:cancel')}
          </Button>
          <Tooltip
            disabled={Boolean(validIdsToAdd.length)}
            message={t('tooltips:party_add_ids_modal_button')}
            placement='bottom'
            colorType='secondary-black'
          >
            <div>
              <Button size='lg' disabled={!validIdsToAdd.length} onClick={addIdsToNameModule}>
                {t('common:confirm_changes')}
              </Button>
            </div>
          </Tooltip>
        </div>
      }
    >
      <p className={styles.addIdentifiersInfo}>
        {t('party_labels:edit_ids_info')}
      </p>
      <br />
      <SearchIdForm
        validationSetter={idValidationSetter}
        validated={validatedIds.apple.validated}
        icon='apple'
        loading={searchAppleIdLoading}
        searchResult={{
          data: searchAppleIdData?.partySearchAppleIdentifier,
          err: searchAppleIdErr 
        }}
        openForm={isOpen}
        forId='apple'
        label={t('enums:APPLE')}
        defaultValue={validatedIds.apple.value}
        onSubmit={searchAppleIDHandler}
      />
      <div className='mt-26' role='separator'/>
      <SearchIdForm
        validationSetter={idValidationSetter}
        validated={validatedIds.spotify.validated}
        icon='spotify'
        loading={searchSpotifyIdLoading}
        searchResult={{
          data: searchSpotifyIdData?.partySearchSpotifyIdentifier,
          err: searchSpotifyIdErr
        }}
        openForm={isOpen}
        forId='spotify'
        label={t('enums:SPOTIFY')}
        defaultValue={validatedIds.spotify.value}
        onSubmit={searchSpotifyIDHandler}
      />
    </Modal>
  );
};
