import React, { FC, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Autosuggest, {
  GetSuggestionValue,
  OnSuggestionHighlighted,
  OnSuggestionSelected,
  RenderSuggestionsContainerParams,
  ShouldRenderSuggestions,
  SuggestionsFetchRequested,
} from 'react-autosuggest';
import get from 'lodash/get';
import compose from 'lodash/fp/compose';
import { useStorage } from './useStorage';

import { useDebounce } from 'modules/common/hooks/useDebounce';
import { SEARCH_HISTORY } from 'graphql/queries';
import {
  ESuggestionType,
  historyToStorage,
  IItemProps,
  listAdapter,
  MemoryStorage,
  storedListAdapter,
  SuggestionContainer,
  SuggestionItem,
  suggestionSearchAdapter,
} from './components';

import { ISearchHistory, ISearchHistoryParams } from 'graphql/types';
import { ISearchBarProps } from './types';

import styles from './autosuggest.module.scss';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@grow-components/Tooltip';
import { ESearchType, NAMES_SEARCH } from 'domains/search/graphql';
import { TMainSearchResponse, TMainSearchVars } from 'domains/search/hooks/types';
import { Input, Button } from '@wmgtech/legato';
import { EButtonStyles } from 'modules/common/types/legatoTypes';

export const SearchBar: FC<ISearchBarProps> = ({ value, onChange, onSearch }) => {
  const { t } = useTranslation();
  const h = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 400);
  const [allowGetSuggestions, setAllowGetSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<ReadonlyArray<IItemProps>>([]);

  const { init, getList, addItem } = useStorage(MemoryStorage);

  const { data: searchHistoryList } = useQuery<ISearchHistory, ISearchHistoryParams>(
    SEARCH_HISTORY, {
      variables: {
        size: 6
      },
      fetchPolicy: 'no-cache',
      onCompleted (d) {
        const date = new Date().getTime();
        init(listAdapter(historyToStorage(date))(d.searchHistoryGet.items));
      }
    });

  const { data: suggestionsResults, loading: isFetchingSuggestions } = useQuery<TMainSearchResponse,
    TMainSearchVars>(NAMES_SEARCH, {
      fetchPolicy: 'no-cache',
      skip: !allowGetSuggestions,
      variables: {
        searchType: ESearchType.AUTOCOMPLETE,
        searchTerm: debouncedSearchTerm,
        skip: 0,
        size: 10,
      },
      onCompleted: () => {
        setAllowGetSuggestions(false);
      },
    });

  const makeSearch = useCallback(
    () => {
      addItem(value);
      onSearch();
    },
    [onSearch, value, addItem],
  );

  useEffect(() => {
    setAllowGetSuggestions(debouncedSearchTerm.length > 0);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const resultsList = suggestionsResults?.searchNames?.names;
    if (resultsList && value.length > 0) {
      compose(
        setSuggestions,
        listAdapter(suggestionSearchAdapter),
      )(resultsList);
    }
  }, [suggestionsResults]);

  const typedValues = useRef<string | undefined>();

  const getSuggestionValue = useCallback<GetSuggestionValue<IItemProps>>(
    (suggestion: any) => {
      return suggestion.title;
    },
    [],
  );

  const handleOnChange = useCallback(
    (event, params) => {
      if (params.method === 'type') {
        typedValues.current = params.newValue;
      }
      if (params.method !== 'enter') {
        onChange(params);
      }
    },
    [onChange],
  );

  const inputProps = useMemo(
    () => ({
      name: 'term',
      onChange: handleOnChange,
      placeholder: t('search:prompt_placeholder'),
      value,
      className: styles.input,
      isLoading: isFetchingSuggestions
    }),
    [value, handleOnChange, t, isFetchingSuggestions],
  );

  const onSuggestionsFetchRequested = useCallback<SuggestionsFetchRequested>(
    async ({ value, reason }) => {
      const tValue = value.trim();
      if ((reason === 'input-focused' || reason === 'input-changed') && tValue.length === 0) {
        compose(
          setSuggestions,
          listAdapter(storedListAdapter),
        )(await getList());
      }
      setSearchTerm(tValue);
    },
    [searchHistoryList, setSearchTerm],
  );

  const onSuggestionSelected = useCallback<OnSuggestionSelected<IItemProps>>(
    (event, { suggestion }) => {
      const { partyId, type, title } = suggestion;
      if (type === ESuggestionType.STORED) {
        onChange({ newValue: title, method: 'click' });
        makeSearch();
      } else {
        h.push(`/party/${partyId}`);
      }
    },
    [onChange, makeSearch]
  );

  const onSuggestionsClearRequested = useCallback(() => null, []);

  const onSuggestionHighlighted = useCallback<OnSuggestionHighlighted>(
    ({ suggestion }) => {
      const newValue = suggestion?.title;
      if (typeof newValue === 'string') {
        onChange({ newValue, method: 'click' });
      }
    },
    [onChange],
  );

  const shouldRenderSuggestions = useCallback<ShouldRenderSuggestions>(() => true, []);

  const handleResetValue = useCallback(() => {
    if (typeof typedValues.current === 'string') {
      onChange({ newValue: typedValues.current, method: 'type' });
    }
  }, []);

  const renderContainer = useCallback(
    (params: RenderSuggestionsContainerParams) => {
      const titleMessage = t('search:recent_search_terms');
      const title = suggestions.some(e => e.type === ESuggestionType.STORED) ? titleMessage : '';
      return (
        <SuggestionContainer title={title} onReset={handleResetValue} {...params} />
      );
    },
    [suggestions, t],
  );

  const renderSuggestion = useCallback<(d: IItemProps) => JSX.Element>(
    (data) => <SuggestionItem {...data} />,
  [],
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const value: string = get(event, 'target.elements.term.value', '').trim();
      if (value.length > 0) {
        makeSearch();
      }
    },
    [makeSearch],
  );

  const handleReset = useCallback(
    () => { onChange({ newValue: '', method: 'escape' }); },
    [onChange],
  );

  return (
    <form onSubmit={handleSubmit} onReset={handleReset} className={styles.form}>
      <Tooltip text={t('tooltips:party_search_bar')} contentType='INPUT' place="right">
        <div className={styles.container}>
          <Autosuggest
            suggestions={suggestions}
            renderInputComponent={inputProps => (
              <Input
                //TODO: remove from here as far as legato permits
                enterKeyHint='hello'
                ref={inputProps.ref}
                placeholder={inputProps.placeholder}
                value={inputProps.value}
                name={inputProps.name as string}
                onChange={inputProps.onChange}
                onKeyDown={inputProps.onKeyDown}
                onFocus={inputProps.onFocus}
                isClearable={true}
                className={styles.input}
                validation={{
                  pending: isFetchingSuggestions
                }}
                onBlur={inputProps.onBlur}
                autoComplete={inputProps.autoComplete}
                data-test-id='search_input'
              />
            )}
            shouldRenderSuggestions={shouldRenderSuggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionHighlighted={onSuggestionHighlighted}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            renderSuggestionsContainer={renderContainer}
            theme={styles}
          />
          <Button
            htmlType='submit'
            icon='magnifying-glass'
            data-test-id='search_button'
            containerStyle={EButtonStyles.link}
          />
        </div>
      </Tooltip>
    </form>
  );
};
